@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Onest-Medium";
  src: local("Onest-Medium"),
    url(./components/assets/fonts/Onest-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Onest";
  src: local("Onest"),
    url(./components/assets/fonts/Onest-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Onest-Bold";
  src: local("Onest-Bold"),
    url(./components/assets/fonts/Onest-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Onest-Black";
  src: local("Onest-Black"),
    url(./components/assets/fonts/Onest-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Onest-SemiBold";
  src: local("Onest-SemiBold"),
    url(./components/assets/fonts/Onest-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Onest-Thin";
  src: local("Onest-Thin"),
    url(./components/assets/fonts/Onest-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Onest-Light";
  src: local("Onest-Light"),
    url(./components/assets/fonts/Onest-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Onest-ExtraBold";
  src: local("Onest-ExtraBold"),
    url(./components/assets/fonts/Onest-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Onest-ExtraLight";
  src: local("Onest-ExtraLight"),
    url(./components/assets/fonts/Onest-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Outfit-Medium";
  src: local("Outfit-Medium"),
  url(./components/assets/fonts/outfit/Outfit-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Outfit-Bold";
  src: local("Outfit-Bold"),
  url(./components/assets/fonts/outfit/Outfit-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
  url(./components/assets/fonts/inter/Inter-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Epilogue-ExtraBold";
  src: local("Epilogue-ExtraBold"),
  url(./components/assets/fonts/epilogue/Epilogue-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Epilogue-Bold";
  src: local("Epilogue-Bold"),
  url(./components/assets/fonts/epilogue/Epilogue-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoMono-Regular";
  src: local("RobotoMono-Regular"),
  url(./components/assets/fonts/roboto-mono/RobotoMono-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoMono-Medium";
  src: local("RobotoMono-Medium"),
  url(./components/assets/fonts/roboto-mono/RobotoMono-Medium.ttf) format("truetype");
}

html {
  font-family: "Onest";
  tab-size: 0;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 0px !important;
  background-color: #08090a;
}
::-webkit-scrollbar-thumb {
  background: #353739;
  border-radius: 8px;
}

/* ReactTabs */
.react-tabs {
  -webkit-tap-highlight-color: transparent;
  border-bottom: 1px solid #F1F8FF1A;
}

.react-tabs__tab-list {
  padding: 0;
  margin-bottom: -1px;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
}

.react-tabs__tab {
  font-family: 'Onest-Light';
  display: inline-block;
  color: #F1F8FF8C;
  border-bottom: none;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  list-style: none;
  padding: 8px 24px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.react-tabs__tab:hover {
  transition: opacity 0.3s ease;
  opacity: 0.6;
}

.react-tabs__tab--selected {
  font-family: 'Onest';
  color: #FF8800;
  border-bottom: 2px solid #FF8800;
}

.react-tabs__tab--selected:hover {
  opacity: 1;
}

.react-tabs__tab:focus {
  outline: none;
}

.cookiesSettingModalOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index: 50;
}