.leftDiv p {
  font-size: 16px;
  line-height: 24px;
  color: #f1f8ff8c;
  margin: 0;
}

.leftDiv strong {
  display: block;
  color: #f1f8ff;
  font-size: 18px;
  line-height: 26px;
  margin-top: 32px;
  margin-bottom: 16px;
}

.leftDiv li {
  font-size: 16px;
  line-height: 24px;
}

.leftDiv ol li {
  margin-left: 0;
}

.leftDiv ol li:before {
  content: none;
}

.leftDiv ul li {
  position: relative;
  padding-left: 20px;
}

.leftDiv ul li:before {
  content: url("../assets/images/chevronRightDoubleSmOrange.svg");
  position: absolute;
  left: 0;
}

.vacancy-manager__photo {
  margin-bottom: 24px;
}

.vacancy-manager__contacts li svg {
  display: none;
}

.vacancy-manager__contacts li {
  margin-bottom: 10px;

  margin-left: 0px;
  word-wrap: break-word;
}
.vacancy-manager__contacts {
  margin-top: 20px;
}

.vacancy-respond svg {
  height: 0px;
}

.btn:hover {
  opacity: 0.24;
}

.vacancy-respond {
  margin-top: 35px;
  display: flex;
}
.btn svg {
  display: none;
}
.vacancy-respond a {
  color: #08090a;
  padding: 12px;
  text-align: center;
  background: linear-gradient(90deg, #f1f8ff 0%, #d3d3d3 100%);
  transition: all ease-in 0.15s;
  border-radius: 8px;
}
.vacancy-respond a span {
  font-size: 14px;
  line-height: 20px;
  font-family: "Onest";
}

.vacancy-share {
  display: none;
}
