.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  display: block;
  width: auto;
  margin: 0 auto;
  height: auto;
  object-fit: cover;
}
.slider-button.swiper-button-disabled {
  opacity: 0.25;
  cursor: not-allowed;
}

.slider-button.swiper-button-disabled:hover {
  opacity: 0.25;
}
