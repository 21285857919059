.whatWeDoList li::before {
  color: #507bef !important;
  content: "\2022";
  font-weight: bold;
  font-size: x-large;
  display: inline-block;
  width: 1em;
  vertical-align: middle;
  margin-left: -1em;
}

.firstList li::before {
  content: "";
  display: inline-block;
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  margin-right: 15px;

  background-position: center center;
  vertical-align: middle;
  background-image: url("../assets/images/goodBlue.svg");
}

.secondList li::before {
  content: "";
  display: inline-block;
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  margin-right: 15px;
  margin-left: -1em;
  background-position: center center;
  vertical-align: middle;
  background-image: url("../assets/images/goodYellow.svg");
}

li {
  list-style-type: none;
}

td,
th {
  padding: 10px 10px;
}
tr {
  width: 100%;
}

.joinUs {
  width: 100%;
  margin-top: 0px;
}

.ovalBigDiv {
  position: absolute;
  width: 400px;
  right: 0px;
  top: -600px;
  z-index: 0;
}
.ovalBig {
  width: 400px;
}

.joinTeam {
  width: 110px;
  margin-left: 30px;
  margin-top: -5px;
}

.moreAboutUS {
  width: 200px;
  margin: 0;
  padding: 0;
}

.textGradient {
  background-image: linear-gradient(69.85deg, #f54703 13.42%, #fac902 86.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.textGradient:hover {
  background: linear-gradient(25.54deg, #f54703 22.29%, #fac902 156.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* .btnText:hover {
  background: linear-gradient(23.68deg, #f54703 21.7%, #fac902 128.66%);
  color: #f6f8ff;
} */

.mobileArrowList li::before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  margin-right: 15px;
  margin-bottom: 5px;
  background-position: center center;
  vertical-align: middle;
  background-image: url("../assets/images/goodBlue.svg");
}
.mobileArrowList2 li::before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  margin-right: 15px;
  margin-bottom: 5px;
  background-position: center center;
  vertical-align: middle;
  background-image: url("../assets/images/goodYellow.svg");
}

.mobileArrowList li,
.secondList li,
.mobileArrowList2 li,
.firstList li {
  display: flex;
  align-items: center;
}
.mobileArrowList li,
.mobileArrowList2 li {
  padding: 15px 20px;
  margin-left: 0;
}

.smallText {
  font-size: 12px;
}

.join {
  background: linear-gradient(
    23.68deg,
    rgba(245, 71, 3, 0.3) 21.7%,
    rgba(250, 201, 2, 0.3) 128.66%
  );
}
.join:hover {
  background: linear-gradient(
    23.68deg,
    rgba(187, 57, 5, 0.3) 21.7%,
    rgba(228, 184, 5, 0.3) 128.66%
  );
}

.ovalAbout4 {
  right: 0px;
  width: 30%;
  top: -550px;
}

.navbar {
  background: #F1F8FF0D;
  backdrop-filter: blur(56px);
  border: 1px solid var(--Colors-White-White-10, #F1F8FF1A)
  /* backdrop-filter: blur(5px); */
}

.middleBg {
  width: 700px;
  height: 700px;
  margin-top: -40px;
}
.middleText {
  height: 650px;

  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 910px) {
  .middleBg {
    width: 600px;
    height: 600px;
  }
  .middleText {
    height: 500px;
  }
}
@media (max-width: 750px) {
  .middleBg {
    width: 520px;
    height: 520px;
    margin-top: -20px;
  }
  .middleText {
    height: 450px;
    width: auto;
  }
}

@media (max-width: 700px) {
  .middleBg {
    width: 500px;
    height: 500px;
    margin-bottom: 50px;
  }
  .partnersLogo1 {
    margin-top: 30px;
  }
  .middleText {
    height: 450px;
  }
}

@media (max-width: 650px) {
  .header1 {
    width: 90%;
  }
  .middleText {
    height: 420px;
  }
  .middleBg {
    overflow: hidden;
  }
}

@media (max-width: 650px) {
  .header1 {
    width: 90%;
  }
  .middleText {
    height: 420px;
  }
  .middleBg {
    overflow: hidden;
  }
}

@media (max-width: 500px) {
  .header1 {
    width: 90%;
    line-height: 1.4;
  }
  .middleText {
    height: 420px;
  }
  .middleBg {
    width: 550px;
    height: 550px;
    overflow: hidden;
    left: -100px;
  }
  .wrapper {
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .partnersLogo1 {
    margin-top: 40px;
  }
}

@media (max-width: 394px) {
  .header1 {
    width: 100%;
    line-height: 1.4;
  }
  .partnersLogo1 {
    padding-top: 40px;
  }
}
