.contained-btn {
  background: linear-gradient(90deg, #f1f8ff 0%, #d3d3d3 100%);
}

.outlined-btn {
  border: 1px solid var(--Colors-White-White-15, #f1f8ff26);
  box-sizing: border-box;
}

@media (hover: hover) {
  .outlined-btn:hover,
  .contained-btn:hover {
    opacity: 0.6;
  }
  .btn-disabled:hover {
    opacity: 0.25;
  }
}
