html{ scroll-behavior:smooth }

:root {
    --marquee-width: 98vw;
    --marquee-height: 20vh;
    /* --marquee-elements: 12; */ /* defined with JavaScript */
    --marquee-elements-displayed: 7;
    --marquee-element-width: calc(
            var(--marquee-width) / var(--marquee-elements-displayed)
    );
    --marquee-animation-duration: calc(var(--marquee-elements) * 3s);
}

@keyframes scrolling {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(
                calc(-1 * var(--marquee-element-width) * var(--marquee-elements))
        );
    }
}
@keyframes scrollingRight {
    0% {
        transform: translateX(
                calc(-1 * var(--marquee-element-width) * var(--marquee-elements))
        );
    }
    100% {
        transform: translateX(0);
    }
}

#join {
    scroll-behavior: smooth;
}