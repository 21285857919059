.slider-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: all 2s ease;
}

.slider-button-state {opacity: 0; transition: opacity 2s ease;}

.slider-image.active, .slider-button-state.active {
  opacity: 1;
}

.slider-button-year {
  transition: color 1s ease;
}
.slider-button-year.active {
  color: #FF8800;
}

.slider-button-indicator {
  transition: all 1s ease;
  width: 12px;
  min-width: 12px;
  height: 12px;
}
.slider-button-indicator.active {
  border-color: #FF8800;
  width: 16px;
  min-width: 16px;
  height: 16px;
}

.slider-button-line {
  position: relative;
  background: #5f6365;
  height: 1px;
}

.slider-button-line-wrapper::before,.slider-button-line::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  border-top: 1px dashed #08090A;
}

.slider-button-line-wrapper.active {
  background: linear-gradient(90deg, #FF8800 0%, rgba(241, 248, 255, 0.4) 100%);
  transition: all 1s ease;
  opacity: 1;
}

.slider-button-line-wrapper.pre-active {
  background: linear-gradient(90deg, rgba(241, 248, 255, 0.4) 0%, #FF8800 100%);
  transition: all 1s ease;
  opacity: 1;
}

.slider-button-line.active,.slider-button-line.pre-active {
  transition: all 1s ease;
  opacity: 0;
}